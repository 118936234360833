html {
  font-size: 10px;
  line-height: 1.2;
}

html {
  /* scroll-behavior: smooth; */
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: 'Inter', sans-serif;
}

@media (min-width: 770px) {
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background-color: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: #aeadad;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  }

  body {
    scrollbar-width: thin;
  }
}
